<template>
  <div class="pay">
    <h3 class="pay__h3">请选择支付方式</h3>
    <div class="pay__content">
      <h3 class="pay__h3">个人账户支付</h3>
      <div class="pay__content--imgdiv" v-if="distributorType === 'DISTRIBUTE'">
        <!-- 样品、普通订单余额显示 -->
        <div
          v-if="type !== 'recharge' && type !== 'drop_shipping' && type.toUpperCase() !== 'GLOBAL'"
          class="pay__content--imgItem pay__content--imgItem_duigong"
          :class="form.payType === 'DISTRIBUTE' ? 'current-color' : ''"
          @click="choose('DISTRIBUTE')"
          :style="{'height': form.amount > accountAmount ? '115px' : '100px'}"
        >
          <div class="pay__content--box">
            <img
            src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/yue.png"
            class="pay__content--img_duigong"
          />
            <div class="pay__content--wrap">
              <p class="pay__content--title">余额支付</p>
              <p class="pay__content--money">可用余额：¥{{ accountAmount }}</p>
              <div class="pay__content--money-tips-wrap" v-if="form.amount > accountAmount">
                <p class="pay__content--money-tips">余额不足</p>
                <PayRecharge class="pay__content--recharge" ref="payRecharge">
                  <el-button @click="$refs.payRecharge.rechargeModeShow(ownCompanyInfo)" size="mini" type="primary">预付款</el-button>
                </PayRecharge>
              </div>
            </div>
          </div>
          <div class="pay__content--infor">经营主体：{{ ownCompanyInfo.ownCompanyName }}</div>
          <div class="pay__content--square" v-if="form.payType === 'DISTRIBUTE'">
            <p class="pay__content--square-left"></p>
            <p class="pay__content--square-left pay__content--square-left--two"></p>
            <p class="pay__content--square-right">
              <i class="el-icon-check pay__content--square-right--icon"></i>
            </p>
          </div>
        </div>
        <!-- 一件代发订单余额显示 -->
        <template v-if="type === 'drop_shipping' && type.toUpperCase() !== 'GLOBAL'">
          <div
            class="pay__content--imgItem pay__content--imgItem_behalf"
            :class="form.payType === 'DISTRIBUTE' ? 'current-color' : ''"
            @click="choose('DISTRIBUTE')"
            style="height: 160px"
            :key="index"
            v-for="(ownCompanyInfo, index) in accountCompanyInfoList"
          >
            <div class="pay__content--money-top">
              {{ownCompanyInfo.accountSubtype === 'SELF_BRAND_COMPANY' ? '自有仓' : '合作仓'}}订单金额：¥{{ ownCompanyInfo.accountSubtype === 'SELF_BRAND_COMPANY' ? payData.selfOrderPayAmount : payData.internationOrderPayAmount }}
            </div>
            <div class="pay__content--box">
              <img
              src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/yue.png"
              class="pay__content--img_duigong"
            />
              <div class="pay__content--wrap">
                <p class="pay__content--title">余额支付{{ownCompanyInfo.accountSubtype === 'SELF_BRAND_COMPANY' ? '-自有仓' : '-合作仓'}}</p>
                <p class="pay__content--money">可用余额：¥{{ ownCompanyInfo.amount }}</p>
                <div class="pay__content--money-tips-wrap" v-if="(ownCompanyInfo.accountSubtype === 'SELF_BRAND_COMPANY' ? payData.selfOrderPayAmount : payData.internationOrderPayAmount) > ownCompanyInfo.amount">
                  <p class="pay__content--money-tips">余额不足</p>
                  <PayRecharge class="pay__content--recharge" :ref="'recharge' + index">
                    <el-button @click="rechargeModeShow('recharge' + index, ownCompanyInfo)" size="mini" type="primary">预付款</el-button>
                  </PayRecharge>
                </div>
              </div>
            </div>
            <div class="pay__content--infor">经营主体：{{ ownCompanyInfo.subtypeValueName }}</div>
            <div class="pay__content--square" v-if="form.payType === 'DISTRIBUTE'">
              <p class="pay__content--square-left"></p>
              <p class="pay__content--square-left pay__content--square-left--two"></p>
              <p class="pay__content--square-right">
                <i class="el-icon-check pay__content--square-right--icon"></i>
              </p>
            </div>
          </div>
        </template>
        <div
          v-if="type !== 'drop_shipping'"
          class="pay__content--imgItem"
          :class="form.payType === 'alipay' ? 'current' : ''"
          @click="choose('alipay')"
        >
          <img
            src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/alipay2.png"
            class="pay__content--img"
          />
        </div>
        <div
          v-if="type !== 'drop_shipping'"
          class="pay__content--imgItem"
          :class="form.payType === 'weChat' ? 'current' : ''"
          @click="choose('weChat')"
        >
          <img
            src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/wechat_pay2.png"
            class="pay__content--img"
          />
        </div>
      </div>
      <div class="pay__content--imgdiv" v-if="distributorType === 'SALE_CREDIT'">
        <div
          class="pay__content--imgItem pay__content--imgItem_duigong"
          :class="form.payType === 'SALE_CREDIT' ? 'current' : ''"
          @click="choose('SALE_CREDIT')"
        >
          <img
            src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/shouxing.png"
            class="pay__content--img_duigong"
          />
          <div class="pay__content--wrap">
            <p class="pay__content--title">授信额度支付</p>
            <p class="pay__content--money">剩余额度：¥{{ accountAmount }}</p>
          </div>
        </div>
      </div>
      <template v-if="type === 'recharge' && isShowDuigongPay">
        <h3 class="pay__h3">
          企业账户支付
          <p class="pay__content--tips">非企业账户不可使用</p>
        </h3>
        <div class="pay__content--imgdiv">
          <div
            class="pay__content--imgItem pay__content--imgItem_duigong"
            :class="form.payType === 'B2B' ? 'current' : ''"
            @click="choose('B2B')"
          >
            <img
              src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/duigongzhifu.png"
              class="pay__content--img_duigong"
            />
            <div class="pay__content--wrap">
              <p class="pay__content--title">企业网上银行支付</p>
              <p class="pay__content--money">
                支付金额≥{{ allInPayChannelVO.thresholdAmount }}元可以使用
              </p>
            </div>
          </div>
        </div>
      </template>
      <p class="pay__content--text" v-if="payData.expireTime">
        请在<span>
          <TimeClock :date="payData.expireTime || 0" :showMillisecond="false"></TimeClock
        ></span>
        内完成支付，超时后将自动取消订单
      </p>
      <div class="pay__content--footer">
        <p>
          应付金额： <span class="red">¥{{ form.amount }}</span>
        </p>
        <el-button
          v-if="
            form.payType === 'B2B' ||
            form.payType === 'DISTRIBUTE' ||
            form.payType === 'SALE_CREDIT'
          "
          :disabled="isNotPay"
          @click="goPay"
          type="primary"
          class="pay__content--btn"
          >去支付</el-button
        >
        <PayCode
          v-if="form.payType === 'weChat' || form.payType === 'alipay'"
          :disabled="PayCodeDisabled"
          :postData="postData"
          @changePayType="changePayType"
          @sureRefresh="sureRefresh"
          ref="pay"
        >
          <template>
            <el-button
              :disabled="PayCodeDisabled"
              @click="$refs.pay.show()"
              type="primary"
              class="pay__content--btn"
              >去支付</el-button
            >
          </template>
        </PayCode>
      </div>
    </div>
    <el-dialog
      title="请输入支付密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="20%"
    >
      <form @submit.prevent="accountPayPaid">
          <el-input size="large" v-model="payForm.payPassword" show-password></el-input>
          <div class="link" @click="forgetPassword">忘记密码</div>
          <div class="error-msg">
            {{ errorMsg }}
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" native-type="submit" :loading="accountPayPaidLoading"
              >确 定</el-button
            >
          </div>
      </form>
    </el-dialog>
  </div>
</template>

<script>
import PayCode from '@/components/Pay/PayCode';
import TimeClock from '@/components/TimeClock';
import PayRecharge from '@/components/Pay';
import {
  getPayChannel,
  allInPay,
  getCustomerAccount,
  validPayPassword,
  syzgAccountPayPaid,
  getPayBizCode,
  listCustomerAccountCompany
} from '@/api/pay';
import md5 from '@/common/js-md5/md5';
import { RSAencryptedData, EncryptedParam } from '@/common/rsa';
export default {
  name: 'pay',
  components: {
    PayCode,
    TimeClock,
    PayRecharge
  },
  data() {
    return {
      payBizCode: '',
      ownCompanyInfo: {},
      accountPayPaidLoading: false,
      type: this.$route.query.type, // 订单类型
      dialogFormVisible: false, // 支付密码弹窗
      distributorType: '', // 分销商类型
      accountAmount: '', // 账户余额
      form: {
        payType: '', // 支付方式
        amount: 0, // 充值金额
        rechargeType: 'BALANCE' // 付款类型，默认是账户余额
      },
      payForm: {
        payPassword: '' // 支付密码
      },
      PayCodeDisabled: false,
      payData: {},
      errorMsg: '', // 密码错误提示
      allInPayChannelVO: {}, // 对公支付是否展示和使用
      accountCompanyInfoList: [], // 一件代发订单账户信息
      ownCompanyId: ''
    };
  },
  mounted() {
    this.getPayData();
    this.getAccountInfo();
    this.getBizCode();
  },
  computed: {
    // 是否不能支付
    isNotPay() {
      if (this.form.payType === 'B2B') {
        return this.allInPayChannelVO.status === 'SHOW' || this.allInPayChannelVO.status === 'DISABLE';
      }
      if (this.form.payType === 'DISTRIBUTE' || this.form.payType === 'SALE_CREDIT') {
        if(this.type === 'drop_shipping') { // 一件代发订单
          const accountMap = new Map(this.accountCompanyInfoList.map(item => [item.accountSubtype, item.amount]));
          const selfPayAmount = accountMap.get('SELF_BRAND_COMPANY');
          const internationPayAmount = accountMap.get('INTERNATION_BRAND_COMPANY');
          if(accountMap.has('SELF_BRAND_COMPANY') && accountMap.has('INTERNATION_BRAND_COMPANY')) {
            // 同时有【自有仓】和【合作仓】商品
            return this.payData.selfOrderPayAmount > selfPayAmount || this.payData.internationOrderPayAmount > internationPayAmount;
          } else if(accountMap.has('SELF_BRAND_COMPANY')) {
             // 只有【自有仓】商品
            return this.payData.selfOrderPayAmount > selfPayAmount;
          } else if(accountMap.has('INTERNATION_BRAND_COMPANY')) {
             // 只有【合作仓】商品
            return this.payData.internationOrderPayAmount > internationPayAmount;
          }
        } else { // 样品、普通订单
          return this.form.amount > this.accountAmount;
        }
      }
    },
    // 是否展示对公支付
    isShowDuigongPay() {
      return (
        (this.type.toUpperCase() === 'NORMAL' || this.type === 'recharge') &&
        this.allInPayChannelVO.status !== 'DISABLE'
      );
    },
    //对公支付传参
    postDataDuigong() {
      let postData = {};
        const payBizCode = this.payBizCode;
      if (this.type !== 'recharge' && !sessionStorage.getItem('payData')) {
        this.PayCodeDisabled = true;
        return;
      }
      if (this.type === 'recharge' && !sessionStorage.getItem('rechargePayData')) {
        this.PayCodeDisabled = true;
        return;
      }
      if (this.type === 'recharge') {
        postData = JSON.parse(sessionStorage.getItem('rechargePayData'));
        postData.payType = this.form.payType;
        postData.returnUrl = `${process.env.VUE_APP_BASE_URL}/syoung/shop/detail`;
        postData.payBizCode = payBizCode;
      } else {
        const { payAmount = 0, bizType, id } = this.payData;
        postData = {
          payBizCode,
          payType: this.form.payType,
          bizType,
          body: '水羊直供',
          subject: '水羊直供',
          tenantTradeNo: id,
          tradeAmount: payAmount,
          returnUrl: `${process.env.VUE_APP_BASE_URL}/syoung/order/inquiry/detail/${id}`
        };
      }

      return postData;
    },
    // 支付宝和微信支付传参数
    postData() {
      let postData = {};
      if (this.type !== 'recharge' && !sessionStorage.getItem('payData')) {
        this.PayCodeDisabled = true;
        return;
      }
      if (this.type === 'recharge' && !sessionStorage.getItem('rechargePayData')) {
        this.PayCodeDisabled = true;
        return;
      }
      const {
        payAmount = 0,
        bizType,
        id,
        customerTel,
        customerIdNo,
        customerName,
        goodsCount
      } = this.payData;
      if (this.type.toUpperCase() === 'GLOBAL') {
        postData = {
          returnUrl: '/syoung/order/inquiry/list',
          payBizCode: this.payBizCode,
          bizType: bizType,
          displayName: '水羊直供',
          goodsName: '海淘-水羊直供',
          goodsCount: goodsCount,
          payType: this.form.payType,
          tenantTradeNo: id,
          tradeAmount: payAmount,
          UserIdentityInfo: {
            customerIdNo: customerIdNo,
            customerTel: customerTel,
            customerName: customerName
          }
        };
      }
      if (
        this.type.toUpperCase() === 'NORMAL' ||
        this.type.toUpperCase() === 'ACTUAL' ||
        this.type.toUpperCase() === 'VIRTUAL' ||
        this.type.toUpperCase() === 'INTERNATION' ||
        this.type.toUpperCase() === 'SHOPPING' ||
        this.type.toUpperCase() === 'INTERNATIONSHOPPING'
      ) {
        postData = {
          payType: this.form.payType,
          payBizCode: this.payBizCode,
          bizType: bizType,
          body: '水羊直供',
          subject: '水羊直供',
          tenantTradeNo: id,
          tradeAmount: payAmount
        };
      }
      if (this.type === 'recharge') {
        postData = JSON.parse(sessionStorage.getItem('rechargePayData'));
        postData.payType = this.form.payType;
        postData.payBizCode = this.payBizCode;
      }
      return postData;
    }
  },
  methods: {
    // 一件代发订单-获取账户信息
    getListCustomerAccountCompany() {
      listCustomerAccountCompany({
        accountType: 'BALANCE',
        subtypeValue: this.payData.ownCompanyId
      }).then(res => {
        this.accountCompanyInfoList = res.data;
      });
    },
    getPayData() {
      if (this.type === 'recharge') {
        this.payData = JSON.parse(sessionStorage.getItem('rechargePayData'));
        this.form.amount = this.payData.tradeAmount;
      } else {
        this.payData = JSON.parse(sessionStorage.getItem('payData'));
        this.form.amount = this.payData.payAmount;
      }
      if(this.type === 'drop_shipping') {
        this.getListCustomerAccountCompany();
      }
    },
    //获取支付服务bizCode
    async getBizCode(){
      getPayBizCode(this.postData)
        .then((response) => {
          if (response.data.payBizCode) {
            this.payBizCode = response.data.payBizCode;
          }else{
            this.$message.error(response.msg);
          }
        })
        .finally(() => {});
    },
    // 余额不足充值
    rechargeModeShow(pay, companyInfoVO = {}) {
      this.$refs[pay][0].rechargeModeShow({
        ownCompanyId: companyInfoVO.subtypeValue
      });
    },
    // 授信/余额支付
    accountPayPaid() {
      if(!this.payForm.payPassword){
        this.$message.error('支付密码不能为空');
        return;
      }
      this.accountPayPaidLoading = true;
          const postData = {
            payPassword: RSAencryptedData(md5(this.payForm.payPassword)), // rsa加密
            bizId: this.payData.id,
            bizType: this.payData.bizType,
            payAmount: this.payData.payAmount
          };
          const timestamp = Date.now(); // 时间戳
          let companyPayJson = ''; 
          let ownCompanyId = '';
          if (this.type === 'drop_shipping') {
            postData.isUseVirtualCredit = this.payData.isUseVirtualCredit;
            if(this.accountCompanyInfoList.length > 1) {
              companyPayJson = this.accountCompanyInfoList.map(item => {
                return {
                  ownCompanyId: item.subtypeValue,
                  payAmount: item.accountSubtype === 'SELF_BRAND_COMPANY' ? this.payData.selfOrderPayAmount : this.payData.internationOrderPayAmount,
                  brandType: item.accountSubtype === 'SELF_BRAND_COMPANY' ? 'NORMAL' : 'INTERNATION'
                };
              });
              postData.companyPayJson = companyPayJson = JSON.stringify(companyPayJson);
            }
          }
          if(this.ownCompanyId) {
            ownCompanyId = this.ownCompanyId;
            postData.ownCompanyId = ownCompanyId;
          }
          syzgAccountPayPaid({ ...postData, companyPayJson, ownCompanyId }, EncryptedParam(postData, timestamp), timestamp)
            .then((response) => {
              switch (response.code) {
                case 'DISTRIBUTOR_CODE_501':
                case 'DISTRIBUTOR_CODE_502':
                  this.$confirm(response.msg, '温馨提示', {
                    confirmButtonText: '去完善',
                    cancelButtonText: '取消',
                    type: 'warning',
                  }).then(() => {
                    this.$router.replace(`/syoung/register/step?supplementStatus=${response.code === 'DISTRIBUTOR_CODE_501' ? 'NO' : 'WAIT'}`);
                  });
                return;
              }
              if (response.code === '0') {
                this.$message({
                  type: 'success',
                  message: '支付成功'
                });
                this.accountPayPaidLoading = false;
                // 如果是小样商品，支付成功则跳转到样品领用记录页
                if (this.$route.query.goodsType && this.$route.query.goodsType === 'sample') {
                  this.$router.replace(`/syoung/sample-receipt-record/list`);
                  return;
                }
                this.$router.push('/syoung/order/inquiry/list');
                return;
              }
              this.$message({
                type: 'error',
                message: response.msg
              });
            })
            .finally(() => {
              this.accountPayPaidLoading = false;
            });
    },
    // 忘记密码
    forgetPassword() {
      this.$router.push('/syoung/information/change-pay-password');
    },
    // 是否设置了支付密码
    validPayPassword() {
      validPayPassword().then((response) => {
        if (response.code === '0' && !response.data) {
          this.$confirm(
            '设置后，您在使用支付时将需要输入密码。 如需修改密码请进入【个人信息-支付密码管理】中修改。',
            '请先设置支付密码',
            {
              confirmButtonText: '去设置',
              cancelButtonText: '取消',
              type: 'warning',
              center: true
            }
          )
            .then(() => {
              this.$router.push('/syoung/information/change-pay-password');
              return;
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
            });
          return;
        }
        if (response.code === '0' && response.data) {
          // 弹出输入支付密码弹窗
          this.dialogFormVisible = true;
        }
      });
    },
    // 获取收银台账户信息-余额/授信
    getAccountInfo() {
      getCustomerAccount({
        accountType: 'BALANCE',
        brandType: this.payData?.brandType || ''
      }).then((response) => {
        if (response.code === '0') {
          this.accountAmount = response.data.accountAmount || 0;
          this.distributorType = response.data.distributorType;
          this.ownCompanyId = response.data?.ownCompanyId || '';
          this.ownCompanyInfo = {
            ownCompanyId: response.data?.ownCompanyId || '',
            ownCompanyName: response.data?.ownCompanyName || ''
          };
          this.getPayChannel();
        }
      });
    },
    // 获取默认支付方式
    getPayChannel() {
      getPayChannel(this.form.amount, this.ownCompanyId || this.payData?.ownCompanyId || '').then((response) => {
        if (response.code === '0') {
          this.allInPayChannelVO = response.data;
          // 当是采销账户(非充值和海淘商品)，且余额充足的时候，默认使用余额支付
          if (
            this.type !== 'recharge' && 
            this.type !== 'drop_shipping' &&
            this.type.toUpperCase() !== 'GLOBAL' &&
            this.distributorType === 'DISTRIBUTE' &&
            this.accountAmount >= this.form.amount
          ) {
            this.form.payType = 'DISTRIBUTE';
            return;
          }
          // 如果是采销账户，且是一件代发订单，只能用余额支付
          if (this.distributorType === 'DISTRIBUTE' && this.type === 'drop_shipping') {
            this.form.payType = 'DISTRIBUTE';
            return;
          }
          // 当是采销账户，余额不足时，如果可以使用企业网上银行支付，默认选择企业网上银行支付
          if (
            this.distributorType === 'DISTRIBUTE' &&
            this.accountAmount < this.form.amount &&
            this.allInPayChannelVO.defaultFlag === '1'
          ) {
            this.form.payType = 'B2B';
            return;
          }
          // 当是赊销账户，使用赊销支付
          if (this.distributorType === 'SALE_CREDIT') {
            this.form.payType = 'SALE_CREDIT';
            return;
          }
          // 其他情况，默认微信支付
          this.form.payType = 'weChat';
        }
      });
    },
    // 去付款
    goPay() {
      // 企业网上银行支付
      if (this.form.payType === 'B2B') {
        allInPay(this.postDataDuigong).then((response) => {
          const div = document.createElement('div');
          div.innerHTML = response; //此处form就是后台返回接收到的数据
          document.body.appendChild(div);
          document.forms[0].submit();
        });
        return;
      }
      // 判断是否有支付密码，无则去设置密码，有则输入支付密码
      this.validPayPassword();
    },
    // 获取支付方式，默认是微信支付,如果用户有切换支付方式则选择用户的支付方式
    getPayType() {
      // 当用户上次有付款的时候，选择用户上次的付款方式
      if (localStorage.getItem('otherPay')) {
        return localStorage.getItem('otherPay') === '微信' ? 'weChat' : 'alipay';
      }
      return 'weChat';
    },
    // 切换支付方式
    changePayType(value) {
      this.form.payType = value === '微信' ? 'weChat' : 'alipay';
      this.$nextTick(() => {
        this.$refs.pay.show();
      });
    },
    choose(payType) {
      // 支付类型
      this.form.payType = payType;
    },
    // 付款完成
    sureRefresh() {
      if (this.type === 'recharge') {
        this.$router.replace('/syoung/shop/detail');
      } else {
        if (this.$route.query.goodsType && this.$route.query.goodsType === 'sample') {
          this.$router.replace(`/syoung/sample-receipt-record/list`);
        } else {
          this.$router.replace(`/syoung/order/inquiry/detail/${this.payData.id}`);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import './index';
</style>
